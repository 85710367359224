<template>
  <div>
    <h4>Tabla encuestadores</h4>
    <b-table :items="items" :fields="fields" striped responsive="sm">
      <template #cell(pass)>
        <b-input placeholder="Inserte el password" type="password" @change="cambiar_Password" v-model="items_.pass_"></b-input>
        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
      </template>



      <template #cell(estatus)>
        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <b-form-checkbox @input="cambiarEstatus2" switch>
          Estatus del encuestador: <b>({{ items.isActive }})</b>
        </b-form-checkbox>
      </template>

    

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Nombre:</b></b-col>
            <b-col>{{ row.item.nombre }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
            <b-col>{{ row.item.isActive }}</b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>

      <b-card class="mt-3" header="Tabla encuestas">
          <pre class="m-0">{{ items }}</pre>
      </b-card>

      <b-card class="mt-3" header="Tabla encuestas">
          <pre class="m-0">{{ items_ }}</pre>
      </b-card>

  </div>  
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        fields: ['nombre', 'pass', 'estatus'],
        items: [
          { isActive: "Preparacion", nombre: "Encuestador 01", pass: '12/03/2024'},
          { isActive: "Preparacion", nombre: "Encuestador 02", pass: '12/03/2024'}
        ],

        items_: {
          isActive_: "Preparacion", 
           pass_: '12/03/2024',
          },



        permisos: 'yes',

        

      }

      
    },
    methods: {
      cambiar_Password(){
          alert('Se ha actualizado su contraseña.');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
          },

      descargar_Resultados(){
          alert('Ha comenzado la descarga. Verifique su carpeta de descargas para visualizar su archivo.');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
          },

        cambiarEstatus() {
          alert('Se ha actualizado el estatus del encuestador.');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
      },

      cambiarEstatus2() {
          alert('Se ha actualizado el estatus de la encuesta.');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
      },
    }

  
  }



  
</script>





